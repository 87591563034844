<template>
  <!-- <Layout /> -->
  <router-view></router-view>
</template>

<script>
// import Home from './components/Home.vue';
// import Layout from './components/Layout.vue'


export default {
  name: 'App',
  components: {
    // Layout,
    // Home
  }
}
</script>

<style>

</style>
