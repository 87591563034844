<template>
  <div class="foot-content">
    <div class="left">
      <span class="socials">
        <a href="https://www.facebook.com/gelatojunkie/"><font-awesome-icon class="social-icons" :icon="['fab', 'facebook']" size="2xl" /></a>
        <a href="https://www.instagram.com/gelatojunkienz/"><font-awesome-icon class="social-icons" :icon="['fab', 'instagram']" size="2xl" /></a>
      </span>
    </div>
    <div class="center">
      <img src="../../public/images/brand-address.png" alt="Gelato Junkie, number 8 the octagon, Dunedin" />
    </div>
    <div class="right">
      <NavTags />
    </div>
  </div>
</template>

<script>
import NavTags from "./NavTags.vue";

export default {
  name: "FooterPage",
  components: {
    NavTags,
  },
};
</script>

<style lang="scss">
.foot-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1rem;
  background-color: var(--secondary-colour);
  color: var(--text-colour);
  position: relative;
  // clear: left;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 30rem;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    a {
      padding: 1rem;
      text-decoration: none;
      color: var(--primary-colour);
      transition: 500ms ease-in-out;
      &:hover {
        color: var(--text-colour);
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .foot-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .socials {
        padding: 1rem 1rem;
        .social-icons {
          padding: 0.5rem 0.5rem;
          color: var(--primary-colour);
          transition: all 0.5s ease-in-out;
        }
        .social-icons:hover {
          color: var(--text-colour);
      }
    }
  }
    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 15rem;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 2rem;
      a {
        padding: 1rem;
        text-decoration: none;
        color: var(--text-colour);
        transition: 500ms ease-in-out;
        &:hover {
          color: var(--action-colour);
        }
      }
    }
  }
}
</style>
