<template>
  <div>
    <h1>Login</h1>
    <form @submit="login">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username" required />
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required />
      <button @click.stop.prevent="login" type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {
    
  },
  methods: {
    
    async login() {
      try {
        const response = await fetch(process.env.VUE_APP_WEB_SHOP_API_URL + "/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username: this.username, password: this.password }),
        });

        const result = await response.json();

        if ("access_token" in result) {
          // Store the token in localStorage
          localStorage.setItem("token", JSON.stringify({ token: result.access_token, expires_in: result.expiration }));
          console.log(result);
          this.$router.push({ name: "DashboardItem" });
        } else {
          const errorMessage = result.message || "Login failed. Please try again.";
          console.error(errorMessage);
        }
      } catch (error) {
        console.log("An error occurred during login:", error);
      }
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
