<template>
  <div class="dashboard-container">
    <header class="header">
      <div>
        <h1>Dashboard</h1>
      </div>
    </header>
    <main class="orders">
      <section class="new-orders">
        <h2>New Orders</h2>
        <div>
          <ul>
            <li v-for="order in futureOrders" :key="order.id">
              <h1>Order:</h1>
              <div v-for="item in order.value.cart" :key="item" class="cake-order">
                <h3 v-if="item.cakeType != 'custom'">{{ item.quantity }} x {{ item.size }} {{ item.cakeType }} Cake</h3>
                <div v-else>
                  <h3>{{ item.quantity }} x {{ item.size }} custom cake with following flavours:</h3>
                  <p v-for="flavour in item.selectedFlavours" :key="flavour">
                    -
                    <span v-if="flavour == ''">no flavour selected</span>
                    {{ flavour }}
                  </p>
                </div>
              </div>
              <h3>Collection date: {{ order.value.collectionDate }}</h3>
              <div>
                <h3>Order by {{ order.value.name }}</h3>
                <h3>Customer email: {{ order.value.email }}</h3>
                <h3>Customer phone: {{ order.value.phone }}</h3>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <!-- <section class="complete-orders">
        <div class="completed-orders">
          <h2>Orders to begin today</h2>
          <ul>
            <li v-for="order in todaysOrders" :key="order.id">
              {{ order }}
            </li>
          </ul>
        </div>
        {{ todaysOrders }}
      </section> -->
    </main>
    <footer>
      <div></div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      fetchedData: [],
      newOrders: [],
      currentOrders: [],
      ordersCollectingToday: [],
      completedOrders: [],
      upAndComingOrders: [],
    };
  },
  computed: {
    futureOrders() {
      return this.fetchedData.filter((order) => new Date(order.key) >= Date.now());
    },
    todaysOrders() {
      return this.fetchedData.filter((order) => new Date(order.key) == Date.now());
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.sortAndCategorise();
  },

  methods: {
    async fetchData() {
      try {
        const response = await fetch(process.env.VUE_APP_WEB_SHOP_API_URL + "/get/data", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const data = await response.json();
        this.fetchedData = data;

        this.sortAndCategorise();
      } catch (error) {
        console.error(error);
      }
    },

    sortAndCategorise() {
      console.log(this.fetchedData);
    },
  },
};
</script>
<style lang="scss">
.dashboard-container {
  .header {
    display: flex;
    justify-content: center;
  }
  .orders {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: var(--secondary-color);
    margin: 1rem;
    padding: 1rem;
    width: 100%;
    .new-orders {
      h2 {
        text-align: center;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem;
      padding: 1rem;
      width: 50%;
      // border: 2px solid black;
      div {
        ul {
          list-style: none;
          padding: 0;
          .cake-order {
            text-transform: capitalize;
          }
          li:nth-child(even) {
            background-color: var(--secondary-colour);
            border-radius: 5px;
            box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
            padding: 1rem;
            margin: 1rem;
          }
          li:nth-child(odd) {
            background-color: var(--action-colour);
            border-radius: 5px;
            box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
            padding: 1rem;
            margin: 1rem;
          }
        }
      }
    }
  }
}
</style>
