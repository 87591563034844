<template>
  <div class="carousel">
    <div class="carousel-container">
      <button v-if="currentIndex === 1" @click="prevSlide"><font-awesome-icon class="arrow" size="xl" icon="circle-arrow-left" /></button>
      <template v-for="(item, idx) in items" :key="idx">
        <div class="slide" v-if="idx === currentIndex">
          <p>{{ item.content }}</p>
          <h3>{{ item.heading }}</h3>
          <img :src="item.image" alt="carousel image" />
        </div>
      </template>
      <button v-if="currentIndex === 0" @click="nextSlide"><font-awesome-icon class="arrow" size="xl" icon="circle-arrow-right" /></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselItem",
  data() {
    return {
      currentIndex: 0,
      intervalID: null,
      items: [
        {
          heading: "Meet Leni, your host and gelato maker.",
          content:
            " A German-trained chef who wanted to bring the gelato she grew up with in Europe, to Dunedin. So, she re-trained in Italy, and opened Gelato Junkie in 2019. Since then, she’s has made every single batch of our product herself.",
          image: "./images/selling3.jpg",
        },
        {
          heading: "She’s also a self-confessed gelato junkie.",
          content:
            " And you thought the name was about you... When just six years old she was asked what she wanted to do when she grew up. “Eat gelato and have an Piaggio Ape” was her reply. So, Leni is truly living her dream. And sharing it with you.",
          image: "./images/gelato-leni.jpg",
        },
        // Add more items as needed
      ],
    };
  },
  // computed: {
  //   currentItemContent() {
  //     if (this.items[this.currentIndex]) {
  //       return this.items[this.currentIndex].content;
  //     } else {
  //       return this.items[Object.keys(this.items).length].content;
  //     }
  //   },
  // },
  methods: {
    delay() {
      setTimeout(() => {
        const el = document.querySelector(".slide");
        el.style.setProperty("transform", "translateX(-120%)");
        el.style.setProperty("display", "inherit");
      }, 1000);
    },
    nextSlide() {
      const el = document.querySelector(".slide");
      el.style.setProperty("transform", "translateX(-120%)");

      setTimeout(() => {
        const el = document.querySelector(".slide");
        el.style.setProperty("display", "hidden");
        el.style.setProperty("padding-left", "120%");
        this.currentIndex === this.items.length - 1 ? (this.currentIndex = 0) : this.currentIndex++;
        // this.delay();
        // el.style.setProperty("transform", "translateX(0%)");
      }, 1000);

      // Increment the currentIndex
    },

    prevSlide() {
      const el = document.querySelector(".slide");
      el.style.setProperty("transform", "translateX(100%)");
      setTimeout(() => {
        this.currentIndex === 0 ? (this.currentIndex = this.items.length - 1) : this.currentIndex--;
      }, 1000);
      console.log(this.currentIndex);
    },
    restartInterval() {
      clearInterval(this.intervalID);
      this.intervalID = setInterval(() => {
        this.nextSlide();
      }, 15000);
    },
  },
  // mounted() {
  //   this.restartInterval();
  // },
  // beforeUnmount() {
  //   clearInterval(this.intervalID);
  // },
};
</script>

<style lang="scss">
.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  height: 100%;
  margin: 2rem;
  overflow: hidden;
  border-radius: 30px;
  z-index: 10;
  .carousel-container {
    display: flex;
    transition: 2000ms;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
    button {
      border: none;
      background-color: #afd8dd;
      opacity: 100%;
      width: 6rem;
      transition: 1000ms ease-in-out;
      z-index: 100;
      .arrow {
        transition: 1000ms ease-in-out;
        color: #fff;
        font-size: 4rem;
      }
      &:hover {
        .arrow {
          opacity: 100%;
        }
      }
    }
    .slide {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 1rem;
      color: var(--text-colour);
      transition: transform 1000ms ease-in-out;
      background-position: top;
      border-radius: 20px;
      background-color: var(--secondary-colour);
      p, h3 {
        width: 70%;
        border-radius: 10px;
        padding: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        text-align: center;
        font-size: x-large;
        margin: 0;
        color: #6b7474;
      }
      img {
        width: 50%;
        border-radius: 10px;
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .carousel {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    border-radius: 0;
    .carousel-container {
      width: 100%;
      border-radius: 0;
      button {
        width: 4rem;
        z-index: 100;
        .arrow {
        font-size: 1.5rem;
      }
      }
      
      .slide {
        display: flex;
        flex-direction: column-reverse;
        justify-content: top;
        align-items: center;
        width: 100%;
        img {
          width: 90%;
        }
        p {
          font-size: medium;
          padding: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 840px) {
  .carousel {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    border-radius: 0;
    .carousel-container {
      width: 100%;
      border-radius: 0;
      button {
        width: 4rem;
        z-index: 100;
        .arrow {
        font-size: 3.5rem;
      }
      }
      
      .slide {
        display: flex;
        flex-direction: column-reverse;
        justify-content: top;
        align-items: center;
        width: 100%;
        img {
          width: 60%;
        }
        p {
          font-size: large;
          padding: 0;
        }
      }
    }
  }
}

</style>
