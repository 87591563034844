<template>
  <Layout>
    <section v-if="annualLeave" class="holiday">
      <div class="warning-text">
        <h1>Annual Leave</h1>
        <h2>Sorry, we are closed for annual leave from {{ formattedDates.leaveDate }} to {{ formattedDates.returnDate }}.</h2>
      </div>
    </section>
    <div class="cake-shop-container">
      <section class="cake-shop-head">
        <h1>Cake Shop</h1>
        <h3>
          Crafting our gelato cakes requires three days.<br />Be sure to order yours in plenty of time<br />
          to enjoy every scrumptious moment.
        </h3>
      </section>
      <div class="cake-picker">
        <section class="expand">
          <div class="layer1">
            <h3>Let's get you a touch of personalised perfection.</h3>
            <h4>Pick from the options below</h4>
          </div>

          <button class="layer2" @click="expand">
            <h2 v-if="cartTotal == 0">Select your Cake</h2>
            <h2 v-else>Select one more...</h2>
          </button>

          <form class="layer3 reset">
            <h3>Cake Size</h3>
            <select class="size" v-model="cakeSize">
              <option value="select" selected>Select a Size</option>
              <option value="small">
                <h3>Small (4 people)</h3>
              </option>
              <option value="medium">
                <h3>Medium (6 people)</h3>
              </option>
              <option value="large">
                <h3>Large (8 people)</h3>
              </option>
            </select>
            <div class="radio-option">
              <fieldset name="cake-type" id="">
                <div>
                  <label for="blackforest">Blackforest <br />Cake</label>
                  <input type="radio" value="blackforest" name="cake-type" v-model="cakeType" />
                </div>
                <div>
                  <label for="tiramisu">Tiramisu <br />Cake</label>
                  <input type="radio" value="tiramisu" name="cake-type" v-model="cakeType" />
                </div>
                <div>
                  <label for="custom">Create your <br />own Cake</label>
                  <input type="radio" value="custom" name="cake-type" v-model="cakeType" />
                </div>
              </fieldset>
            </div>
            <div v-if="cakeType == 'blackforest'" class="blackforest">
              <h1>{{ cakeType }} <br />cake</h1>
            </div>
            <div v-if="cakeType == 'tiramisu'" class="tiramisu">
              <h1>{{ cakeType }} <br />cake</h1>
            </div>
            <div v-if="cakeType == ''" class="choose">
              <h1 v-if="noCakeSelected == true">Pick a cake</h1>
              <h2 v-else>Two is always<br />better than one</h2>
            </div>
            <div v-if="cakeType == 'custom'">
              <h3>First Flavour {{ firstFlavour }}</h3>
              <select id="first" class="first flav" v-model="firstFlavour" required>
                <option disabled>Pick a flavour</option>
                <option v-for="flavour in flavours" :key="flavour.id">
                  <h3>{{ flavour.name }}</h3>
                </option>
              </select>
              <h3>Second Flavour <span>(Optional)</span></h3>
              <select class="second flav" v-model="secondFlavour">
                <option disabled>Pick a flavour</option>
                <option v-for="flavour in flavours" :key="flavour.id">
                  <h3>{{ flavour.name }}</h3>
                </option>
              </select>
              <div v-if="cakeSize == 'large' || cakeSize == 'medium'">
                <h3>Third Flavour <span>(Optional)</span></h3>
                <select class="third flav" v-model="thirdFlavour" required>
                  <option disabled>Pick a flavour</option>
                  <option v-for="flavour in flavours" :key="flavour.id">
                    <h3>{{ flavour.name }}</h3>
                  </option>
                </select>
                <h3>Fourth Flavour <span>(Optional)</span></h3>
                <select class="fourth flav" v-model="fourthFlavour">
                  <option disabled>Pick a flavour</option>
                  <option v-for="flavour in flavours" :key="flavour.id">
                    <h3>{{ flavour.name }}</h3>
                  </option>
                </select>
              </div>
            </div>
            <button type="submit" @click.stop.prevent="addToCart">Add to Cart</button>
          </form>
        </section>
        <section :class="showCart ? 'cartOpen' : 'cartClose'" id="cart">
          <div class="table-head">
            <h1>Cart</h1>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Size</th>
                  <th>Flavours</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in cart" :key="item.index">
                  <td>{{ item.size }}</td>
                  <td>
                    <p v-for="flavour in item.selectedFlavours" :key="flavour">{{ flavour }}</p>
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>$ {{ item.price }}</td>
                  <td>
                    <a @click.stop.prevent="removeItemFromCart(index)"><font-awesome-icon :icon="['fas', 'trash-can']" /></a>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">Credit Card Fee 3%</td>
                  <td>$ {{ cardFee }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>total</th>
                  <td colspan="2"></td>
                  <td>$ {{ cartTotal }}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </section>
      </div>
    </div>
    <section class="payment-container">
      <section class="validate">
        <div class="payment-form">
          <form class="form">
            <label for="name"><span class="needed">*</span> Full Name</label>
            <input type="text" name="name" id="name" placeholder="Name" v-model="name" />
            <label for="email"><span class="needed">*</span> Email Address</label>
            <input type="email" name="email" id="email" placeholder="Email" v-model="email" />
          </form>
          <form>
            <label for="phone"><span class="needed">*</span> Phone Number</label>
            <input type="tel" name="phone" id="phone" placeholder="Phone" v-model="phone" />
            <label for="collectionDate"><span class="needed">*</span> Date of collection</label>
            <input
              type="date"
              placeholder="Collection Date"
              name="collectionDate"
              id="collectionDate"
              v-model="collectionDate"
              :disabled="isDayDisabled(collectionDate)"
              :max="new Date(new Date().setDate(new Date().getDate() + 30)).toISOString().split('T')[0]"
              :min="new Date(new Date().setDate(new Date().getDate() + 3)).toISOString().split('T')[0]" />
            <div v-if="mondayClosed" class="pick-another-day">{{ pickupMessage }}</div>
            <div v-if="holiday" class="pick-another-day">{{ holidayMessage }}</div>
          </form>
        </div>

        <div class="button">
          <button type="submit" @click.stop.prevent="paymentIntent">Checkout</button>
        </div>
      </section>
      <section class="payment-section">
        <form @submit.prevent="handleSubmit" id="payment-form">
          <div id="payment-element"></div>
          <button class="pay-button" :disabled="loading" id="submit">
            <span v-if="!loading" id="button-text">Submit Payment</span>
            <span v-if="loading" id="spinner">Loading...</span>
          </button>
        </form>
      </section>
      <div class="notifications">
        <section class="warning">
          <div class="warning-text">
            <h2>We need some details to process your order.</h2>
            <p>Please fill in all the required fields.</p>
          </div>
        </section>
        <section class="payment-success">
          <div class="success-text">
            <h2>Payment Successful</h2>
            <p>
              Thank you for your order {{ name }} please check {{ email }} for purchase confirmation.<br />
              Your cake will be ready for collection {{ collectionDate }}.
            </p>
          </div>
        </section>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";
import sha1 from "sha1";

export default {
  name: "CakeShop",
  components: {
    Layout,
  },
  data() {
    return {
      mondayClosed: false,
      cardFee: 0,
      noCakeSelected: true,
      stripe: null,
      elements: null,
      loading: false,
      message: "",
      pickupMessage: "",
      clientSecret: "",
      showCart: false,
      intentLoaded: false,
      expanded: false,
      cakeSize: "select",
      cart: [],
      cartTotal: 0,
      validated: false,
      name: "",
      email: "",
      phone: "",
      collectionDate: Date.now(),
      cake: {
        selectedFlavours: [],
        size: "",
        id: "",
        quantity: 1,
        price: 0,
        cakeType: "",
      },
      cakeType: "",
      flavours: [
        { id: 1, name: "Chocolate" },
        { id: 2, name: "Tahitian Vanilla" },
        { id: 3, name: "Mango (V)" },
        { id: 4, name: "Passionfruit (V)" },
        { id: 5, name: "Cookies & Cream" },
        { id: 6, name: "Snickers (V)" },
        { id: 7, name: "Mint Choc Chip" },
        { id: 8, name: "Strawberry (V)(seasonal)" },
      ],
      firstFlavour: "",
      secondFlavour: "",
      thirdFlavour: "",
      fourthFlavour: "",
      blackforest: "",
      tiramisu: "",
      annualLeave: false,
      leaveDate: new Date("May 24, 2024"),
      returnDate: new Date("June 21, 2024"),
      todaysDate: new Date(Date.now()),
      holidayMessage: "",
      holiday: false,
    };
  },

  async mounted() {
    const stripeKey = process.env.VUE_APP_STRIPE_KEY;
    // eslint-disable-next-line
    this.stripe = Stripe(stripeKey);

    if (this.todaysDate >= this.leaveDate && this.todaysDate <= this.returnDate) {
      this.annualLeave = true;
    }
  },

  created() {
    if (localStorage.getItem("cart") !== null) {
      this.showCart = true;
      this.loadCartFromLocalStorage();
    }
  },

  beforeUnmount() {
    this.saveCartToLocalStorage();
  },

  methods: {
    createOrder() {
      return {
        order: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          collectionDate: this.collectionDate,
          cart: this.cart,
        },
        payment: {},
        events: { created_at: new Date(Date.now()).toISOString() },
      };
    },

    removeItemFromCart(index) {
      this.cart.splice(index, 1);
      if (this.cart.length === 0) {
        localStorage.removeItem("cart");
        this.showCart = false;
      }
    },

    saveCartToLocalStorage() {
      if (this.cart.length > 0) {
        localStorage.setItem("cart", JSON.stringify(this.cart));
      }
    },

    loadCartFromLocalStorage() {
      this.cart = JSON.parse(localStorage.getItem("cart"));
    },

    async addOrderData(orderData) {
      try {
        const response = await fetch(process.env.VUE_APP_WEB_SHOP_API_URL + "/orders/new", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        });
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.log("a problem occurred", error);
      }
    },

    isDayDisabled() {
      const today = this.collectionDate;
      const day = new Date(today).getDay();
      const awayDate = this.leaveDate.toISOString().split("T")[0];
      const homeDate = this.returnDate.toISOString().split("T")[0];
      if (day == 1 || today >! awayDate && today <! homeDate) {
        this.holiday = false;
        this.mondayClosed = true;
        this.pickupMessage = "Sorry, we are closed Mondays.";        
      } else if (today >= awayDate && today <= homeDate && day !== 1) {
        this.holiday = true;     
        this.mondayClosed = false;   
        this.holidayMessage = "Sorry, we are closed for annual leave from May 24th to June 21st.";
      } else {
        this.holidayMessage = "";
        this.holiday = false;
        this.pickupMessage = "";
        this.mondayClosed = false;
      }
      
    },

    async paymentIntent() {
      let today = new Date();
      let futureDate = today.setDate(today.getDate() + 2);
      let name = document.querySelector("#name").value;
      let email = document.querySelector("#email").value;
      let phone = document.querySelector("#phone").value;
      if (new Date(this.collectionDate).valueOf() < futureDate || !(name !== "") || !(email !== "") || !(phone !== "") || this.cartTotal === 0 || this.mondayClosed) {
        document.querySelector(".warning").style.setProperty("display", "flex");
        document.querySelector(".warning").style.setProperty("transform", "translateX(0%)");
        return;
      } else {
        document.querySelector(".warning").style.setProperty("transform", "translateX(-150%)");
      }
      try {
        const response = await fetch(process.env.VUE_APP_WEB_SHOP_API_URL + "/stripe/payment/intent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: parseInt(this.cartTotal * 100),
            currency: "nzd",
          }),
        });
        const { clientSecret } = await response.json();

        this.clientSecret = clientSecret;

        document.querySelector(".payment-section").style.setProperty("display", "block");

        const paymentDiv = document.querySelector("#payment-element");

        const appearance = {
          theme: "stripe",
          variables: {
            colorPrimary: "#CC247B",
            colorBackground: "#fafaff",
            colorText: "#3B3C36",
          },
        };

        const paymentElementOptions = {
          layout: "tabs",
        };

        this.elements = this.stripe.elements({ appearance, clientSecret });

        const paymentElement = this.elements.create("payment", paymentElementOptions);
        paymentElement.mount(paymentDiv);
      } catch (error) {
        console.log("There was a problem", error);
      }
    },
    async handleSubmit() {
      let elements = this.elements;
      // Confirm the payment
      const result = await this.stripe.confirmPayment({
        elements: elements,
        redirect: "if_required",
        confirmParams: {
          return_url: window.location.href,
          receipt_email: this.email,
        },
      });
      this.loading = true;
      if (result.error) {
        console.log(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          let createOrder = this.createOrder();
          createOrder["payment"] = result.paymentIntent;
          this.addOrderData(createOrder);
          console.log(createOrder);
          this.loading = false;
          this.cart = [];
          this.showCart = false;
          localStorage.removeItem("cart");
          console.log("Payment succeeded");
          document.querySelector(".payment-section").style.setProperty("display", "none");
          const paySuccess = document.querySelector(".payment-success");
          paySuccess.style.setProperty("display", "flex");
          paySuccess.style.setProperty("transform", "translateX(0vw)");
        }
      }
    },

    addToCart() {
      this.noCakeSelected = true;
      this.cake.selectedFlavours = [];
      this.cake.quantity = 1;
      if (this.cakeSize === "small") {
        this.cake.price = 38;
      } else if (this.cakeSize === "medium") {
        this.cake.price = 56;
      } else this.cake.price = 74;
      if (this.cakeType === "custom") {
        if (this.firstFlavour.length !== 0) {
          this.cake.selectedFlavours.push(this.firstFlavour);
          this.cake.selectedFlavours.push(this.secondFlavour);
          this.cake.selectedFlavours.push(this.thirdFlavour);
          this.cake.selectedFlavours.push(this.fourthFlavour);
          document.querySelector(".first").style.removeProperty("border");
        } else {
          document.querySelector(".first").style.setProperty("border", "2px solid red");
          return;
        }
      } else {
        this.cake.selectedFlavours.push(this.cakeType);
        console.log(this.cake);
      }

      if (this.cakeType == "" || this.cakeSize == "select") {
        this.noCakeSeleceted = true;
        console.log("nothing selected", this.noCakeSelected);
      } else {
        this.cake.cakeType = this.cakeType;
        this.cake.id = sha1(this.cake.selectedFlavours.sort().join("") + this.cakeSize + this.cakeType);
        this.cake.size = this.cakeSize;
        this.cakeSize = "small";
        let found = false;
        this.cart.forEach((item) => {
          if (item.id === this.cake.id) {
            found = true;
            item.quantity += 1;
          }
        });

        if (!found) {
          this.cart.push({ ...this.cake });
        }

        if (this.cart.length > 0) {
          this.showCart = true;
        }
        this.firstFlavour = "";
        this.secondFlavour = "";
        this.thirdFlavour = "";
        this.fourthFlavour = "";
        this.expand();
        document.querySelector(".reset").reset();
      }

      console.log(this.cartTotal);
      setTimeout(() => {
        document.getElementById("cart").scrollIntoView({ behavior: "smooth", block: "end" });
      }, 50);
    },
    expand() {
      this.expanded = this.expanded = !this.expanded;

      const el = document.querySelector(".layer1");
      const el2 = document.querySelector(".layer3");

      if (this.expanded) {
        el.style.setProperty("display", "flex");
        el2.style.setProperty("display", "flex");
        setTimeout(() => {
          el.style.setProperty("transform", "translateY(0) scale(1)");
          el2.style.setProperty("transform", "translateY(0) scale(1)");
        }, 100);
        return;
      }
      setTimeout(() => {
        el.style.setProperty("display", "none");
        el2.style.setProperty("display", "none");
      }, 1000);
      el.style.setProperty("transform", "translateY(50%) scale(0)");
      el2.style.setProperty("transform", "translateY(-50%) scale(0)");

      setTimeout(() => {
        this.cakeType = "";
      }, 1000);
    },
  },
  computed: {
    cartItems() {
      return this.cart.length;
    },
    formattedDates() {
      return {
        leaveDate: this.leaveDate.toDateString(),
        returnDate: this.returnDate.toDateString(),
      };
    },
  },
  watch: {
    cart: {
      handler() {
        this.cartTotal = 0;
        this.cart.forEach((item) => {
          this.cartTotal += item.price;
        });
        this.cardFee = this.cartTotal * 0.03;
        this.cartTotal += this.cardFee;
        this.saveCartToLocalStorage();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 100%;

  .payment-section {
    display: none;
    #payment-form {
      .pay-button {
        background-color: var(--primary-colour);
        color: var(--alt-text-colour);
        border-radius: 10px;
        box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
        padding: 1rem;
        margin: 1rem;
        font-size: 1.5rem;
        width: 25vw;
      }
    }
  }
  .validate {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 1rem;
    .payment-form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      form {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        width: 100%;
        align-items: center;
        label {
          width: 80%;
          // padding: 0 0;
          // margin: 0 1rem;
          white-space: nowrap;
          text-align: left;
          .needed {
            color: red;
          }
        }
        input {
          width: 80%;
          padding: 1rem;
          border-radius: 10px;
          margin: 1rem;
          font-size: larger;
          border: 1px solid black;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      button {
        width: 92.5%;
        padding: 1rem;
        border-radius: 10px;
        margin: 0.5rem;
        text-transform: capitalize;
        background-color: var(--primary-colour);
        color: var(--alt-text-colour);
        font-size: 1.5rem;
        box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
      }
    }
  }
  .notifications {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    .payment-success {
      justify-content: center;
      transform: translateX(0vw);
      display: none;
      background-color: var(--action-colour);
      color: var(--primary-colour);
      transition: all 1000ms ease-in-out;
      .success-text {
        text-align: center;
      }
      margin: 2rem;
      padding: 0.5rem;
      border-radius: 10px;
      width: 80%;
    }
    .warning {
      justify-content: center;
      transform: translateX(-100vw);
      display: none;
      background-color: var(--action-colour);
      color: var(--primary-colour);
      transition: all 500ms ease-in-out;
      text-align: center;
      margin: 2rem;
      padding: 0.5rem;
      border-radius: 10px;
      width: 80%;
    }
  }
}

.holiday {
    z-index: 100;
    height: 158.65vh;
    width: 100vw;
    background: var(--background-close);
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    h1, h2 {
      text-align: center;
    }
  }

.cake-shop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--background-colour);

  

  .cake-shop-head {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: top;
    padding: 4rem;
    background-image: url(../../public/images/cake2.jpg);
    background-color: var(--background-colour);
    background-size: cover;
    background-position: center;
    transform: scaleX(-1);
    // background-blend-mode: lighten;
    h1 {
      text-align: left;
      font-size: 3rem;
      font-weight: 600;
      color: var(--primary-colour);
      // padding: 2rem;
      transform: scaleX(-1);
    }
    h3 {
      text-align: left;
      font-size: 1.5rem;
      font-weight: 400;
      color: black;
      // width: 50%;
      transform: scaleX(-1);
    }
    .cake-link {
      background-color: var(--primary-colour);
      border: none;
      border-radius: 0.5rem;
      padding: 1rem;
      margin: 1rem;
      a {
        text-decoration: none;
        color: var(--text-colour);
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    p {
      font-size: 1.5rem;
      color: var(--text-colour);
      padding: 1rem;
      text-align: center;
    }
  }
  .cake-picker {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--background-colour);
    background-size: cover;
    background-position: center;
    transition: all 1000ms ease-in-out;

    .cartOpen {
      background-color: var(--secondary-colour);
      color: var(--text-colour);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 3;
      align-items: center;
      width: 80%;
      border-radius: 4rem;
      margin: 0 2rem 2rem 2rem;
      // padding: 1.5rem;
      .table-head {
        font-size: 2rem;
        font-weight: 600;
        color: var(--primary-colour);
        padding: 1rem 1rem;
        h3,
        h4 {
          margin: 0;
        }
        margin: 0;
      }
      table {
        padding: 1rem 1rem;
        thead {
          tr {
            th {
              padding-right: 1rem;
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            td {
              padding-right: 1rem;
              text-align: left;
              text-transform: capitalize;
            }
          }
        }
        tfoot {
          tr {
            margin: 1rem;
            td,
            th {
              padding-right: 1rem;
              text-align: left;
              text-transform: capitalize;
              margin: 0.5rem;
            }
          }
        }
      }
    }
    .cartClose {
      display: none;
    }

    .expand {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 25%;
      margin: 4rem;
      border-radius: 4rem;
      transition: all 1000ms ease-in-out;

      .radio-option {
        display: flex;
        flex-direction: row;
      }
      .layer1 {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 50%;
        width: 70%;
        border-radius: 2rem 2rem 0rem 0rem;
        box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
        padding: 1rem 0;
        background-color: var(--secondary-colour);
        transition: all 1000ms ease-in-out;
        z-index: 1;
        transform: scale(0);
        // visibility: hidden;
        overflow: hidden;
        text-align: center;
        h3,
        h4 {
          margin: 0.5;
        }
      }
      .layer2 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0.5rem;
        height: 30%;
        width: 450px;
        background-color: var(--primary-colour);
        border-radius: 1rem;
        box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
        z-index: 10;
        white-space: nowrap;
        h2 {
          color: var(--alt-text-colour);
        }
      }
      .layer3 {
        display: none;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // height: 110%;
        width: 70%;
        border-radius: 0rem 0rem 2rem 2rem;
        box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
        margin-bottom: 1rem;
        background-color: var(--secondary-colour);
        transition: all 1000ms ease-in-out;
        z-index: 1;
        transform: scale(0);
        // visibility: hidden;
        overflow: hidden;
        h3 {
          margin: 0.3rem;
          span {
            font-size: small;
          }
        }

        .radio-option {
          fieldset {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            border: none;
            width: 100%;
            label {
              padding: 0.5rem;
              text-align: center;
            }
          }
        }
        .blackforest {
          width: 95%;
          background-image: url(../../public/images/cake1.jpg);
          border-radius: 10px;
          text-transform: capitalize;
          background-size: cover;
          text-align: center;
          transition: all 1000ms ease-in-out;
        }
        .tiramisu {
          width: 95%;
          background-image: url(../../public/images/cake3.jpg);
          border-radius: 10px;
          text-transform: capitalize;
          background-size: cover;
          text-align: center;
          transition: all 1000ms ease-in-out;
        }

        .choose {
          width: 95%;
          background-image: url(../../public/images/cake2.jpg);
          background-position: center;
          border-radius: 10px;
          text-transform: capitalize;
          background-size: cover;
          text-align: center;
          transition: all 1000ms ease-in-out;
        }

        .flav {
          height: 1.5rem;
          width: 10rem;
          background-color: var(--background-colour);
          border-radius: 5px;
        }
        select {
          height: 1.5rem;
          width: 10rem;
          background-color: var(--background-colour);
          border-radius: 5px;
        }
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .flav {
            height: 1.3rem;
            width: 10rem;
            background-color: var(--background-colour);
            border-radius: 5px;
          }
        }
        button {
          height: 4rem;
          width: 10rem;
          margin: 1rem;
          background-color: var(--primary-colour);
          color: var(--alt-text-colour);
          border-radius: 5px;
          box-shadow: 2.5px 2.5px 2.5px var(--text-colour);
        }
      }
    }
    // }
  }
}

@media only screen and (orientation: portrait) {
  .outer-container {
    .cake-shop-container {
      .cake-shop-head {
        padding: 0;
      }
    }

    .payment-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 1rem;

      .payment-form {
        width: 100%;
      }
      .notifications {
        width: 100%;
        .warning {
          width: 100%;
          margin: 0;
        }
        .payment-success {
          .success-text {
            width: 100%;
          }
          // width: 100%;
        }
      }

      .validate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .payment-form {
          display: flex;
          flex-direction: column;
        }
        // .payment-form {
        //   display: flex;
        //   flex-direction: column;
        //   justify-content: center;
        //   align-items: center;
        // }
      }
      .payment-section {
        width: 90%;
        #payment-form {
          display: contents;
          .pay-button {
            width: 100%;
          }
        }
      }
    }
    .cake-picker {
      display: flex;
      flex-direction: column;

      .cartOpen {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0;
        margin: 0;
        width: 100%;
        .table-head {
          h1 {
            margin: 0;
          }
        }
        table {
          width: 100%;
          padding: 1rem 0;
          thead {
            tr {
              text-align: center;
            }
          }

          tr,
          td {
            text-align: center;
          }
        }
      }
      .expand {
        width: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0;
        .layer1 {
          width: 85%;
          margin-top: 1rem;
        }
        .layer2 {
          width: 95%;
        }
        .layer3 {
          width: 85%;
        }
      }
    }
  }
}
</style>
