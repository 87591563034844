<template>
  <Layout>
    <section class="hero">
      <div class="hero-head">
        <h1>
          We know you’re here for a naughty treat,<br />
          so it breaks our heart to inform you that we are,<br />
          in fact, quite well-behaved.
        </h1>
      </div>
    </section>
    <div class="svg-wrapper">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1440 270"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2">
        <g transform="matrix(-1.00411,0.000260088,-0.000242314,-0.93549,1443.75,281.078)">
          <g>
            <g transform="matrix(1,0,0,1,3.97077,-3.91339)">
              <path
                d="M0,128L7.7,117.3C14.245,108.657 44.978,110.486 52.267,76.377C53.664,69.838 48.226,55.894 48.107,46.939C47.845,27.11 64.981,22.661 74.8,25.214C85.882,28.096 95.268,42.536 90.312,57.505C65.703,131.834 124,203 139,202.7C154.8,203 187.853,151.102 189.101,124.084C191.778,66.109 167.351,38.759 187.962,25.244C192.888,22.014 197.76,19.313 202.824,19.657C206.153,19.882 209.261,20.999 214.882,25.244C227.69,34.916 218.301,47.621 217.899,63.408C217.401,83.008 216.94,105.1 217.899,112C219.526,123.697 222.29,125.875 232,154.637C247.692,201.118 267.425,205.849 279,213.3C294.2,224 328.692,162.338 325,124.084C324.38,117.66 328.407,113.427 334.417,112C339.419,110.812 347.987,116.723 345.947,128C343.178,143.304 348.727,177.412 365.735,186.7C384.52,196.958 403,213 418,224C433.5,235 449,245 465,250.7C480,256 495,256 511,266.7C526.5,277 542,299 557,266.7C565.562,249.629 587.625,219.987 596.044,186.7C603.26,158.174 588.973,114.933 604,112C612.612,110.319 628.885,101.093 636.253,90.7C646.338,76.473 685.69,85.916 697,76.544C708.639,66.899 741.515,50.151 740.082,90.7C739.517,106.668 737.49,123.788 743,144C754.287,185.402 779.416,234.833 790,256C805.2,288 845.649,253.23 851.89,235.625C860.834,210.396 867,160 883,160C898.1,160 890.311,186.304 929,181.3C981.28,174.538 979.758,117.165 975,95.926C970.355,75.191 985.66,68.982 998.508,68.066C1009.88,67.255 1026.46,57.472 1022,90.7C1019.19,111.639 1035.79,146.401 1052.53,165.3C1070.47,185.567 1096.92,216.883 1115,224C1130.11,229.946 1145.5,212.45 1161,208C1176.44,203.566 1191.46,209.893 1208,197.3C1231.37,179.505 1257.8,152.054 1254,128C1247.75,88.439 1278.46,81.035 1283.63,109.172C1285.12,117.282 1278.87,135.189 1301,165.3C1311.14,179.096 1332,224 1347,224C1362.6,224 1385.58,194.28 1394,181.3C1408.22,159.37 1420.75,187.262 1432,186.7C1435.19,186.541 1440,192 1440,192L1440,320L0,320L0,128Z"
                style="fill: rgb(93, 35, 65); fill-rule: nonzero" />
            </g>
            <path
              d="M0,128L7.7,117.3C14.245,108.657 44.978,110.486 52.267,76.377C53.664,69.838 48.226,55.894 48.107,46.939C47.845,27.11 64.981,22.661 74.8,25.214C85.882,28.096 95.268,42.536 90.312,57.505C65.703,131.834 124,203 139,202.7C154.8,203 187.853,151.102 189.101,124.084C191.778,66.109 167.351,38.759 187.962,25.244C192.888,22.014 197.76,19.313 202.824,19.657C206.153,19.882 209.261,20.999 214.882,25.244C227.69,34.916 218.301,47.621 217.899,63.408C217.401,83.008 216.94,105.1 217.899,112C219.526,123.697 222.29,125.875 232,154.637C247.692,201.118 267.425,205.849 279,213.3C294.2,224 328.692,162.338 325,124.084C324.38,117.66 328.407,113.427 334.417,112C339.419,110.812 347.987,116.723 345.947,128C343.178,143.304 348.727,177.412 365.735,186.7C384.52,196.958 403,213 418,224C433.5,235 449,245 465,250.7C480,256 495,256 511,266.7C526.5,277 542,299 557,266.7C565.562,249.629 587.625,219.987 596.044,186.7C603.26,158.174 588.973,114.933 604,112C612.612,110.319 628.885,101.093 636.253,90.7C646.338,76.473 685.69,85.916 697,76.544C708.639,66.899 741.515,50.151 740.082,90.7C739.517,106.668 737.49,123.788 743,144C754.287,185.402 779.416,234.833 790,256C805.2,288 845.649,253.23 851.89,235.625C860.834,210.396 867,160 883,160C898.1,160 890.311,186.304 929,181.3C981.28,174.538 979.758,117.165 975,95.926C970.355,75.191 985.66,68.982 998.508,68.066C1009.88,67.255 1026.46,57.472 1022,90.7C1019.19,111.639 1035.79,146.401 1052.53,165.3C1070.47,185.567 1096.92,216.883 1115,224C1130.11,229.946 1145.5,212.45 1161,208C1176.44,203.566 1191.46,209.893 1208,197.3C1231.37,179.505 1257.8,152.054 1254,128C1247.75,88.439 1278.46,81.035 1283.63,109.172C1285.12,117.282 1278.87,135.189 1301,165.3C1311.14,179.096 1332,224 1347,224C1362.6,224 1385.58,194.28 1394,181.3C1408.22,159.37 1420.75,187.262 1432,186.7C1435.19,186.541 1440,192 1440,192L1440,320L0,320L0,128Z"
              style="fill: rgb(204, 36, 123); fill-rule: nonzero" />
            <path d="M202.725,31.076C198.563,31.076 194.28,31.886 191.57,35.279" style="fill: rgb(204, 36, 123)" />
            <path
              d="M202.725,24.662C206.023,24.662 208.7,27.536 208.7,31.076C208.7,34.616 206.023,37.489 202.725,37.489C200.307,37.489 197.681,37.483 196.107,39.453C193.96,42.141 190.185,42.452 187.681,40.148C185.177,37.844 184.887,33.792 187.034,31.104C190.884,26.284 196.81,24.662 202.725,24.662Z"
              style="fill: white" />
            <path d="M335.082,117.345C335.082,116.937 333.344,117.199 333.143,117.253C332.034,117.548 331.532,118.66 331.55,119.752" style="fill: rgb(204, 36, 123)" />
            <path
              d="M334.144,119.821L334.038,119.831C334,121.249 332.928,122.399 331.593,122.424C330.219,122.449 329.084,121.273 329.06,119.798C329.024,117.506 330.218,115.278 332.543,114.659C332.823,114.584 334.809,114.364 335.591,114.606C337.096,115.073 337.572,116.258 337.572,117.345C337.572,118.82 336.457,120.017 335.082,120.017C334.751,120.017 334.434,119.948 334.144,119.821ZM332.754,118.292C332.65,117.997 332.593,117.678 332.593,117.345C332.593,117.681 332.645,117.997 332.754,118.292Z"
              style="fill: white" />
            <path d="M602.922,121.603C602.922,119.844 603.813,118.551 605.508,118.181" style="fill: rgb(204, 36, 123)" />
            <path
              d="M605.412,121.603C605.412,123.078 604.296,124.276 602.922,124.276C601.548,124.276 600.432,123.078 600.432,121.603C600.432,118.495 602.017,116.216 605.011,115.562C606.357,115.268 607.673,116.202 607.947,117.648C608.221,119.093 607.351,120.505 606.004,120.799C605.61,120.886 605.412,121.194 605.412,121.603Z"
              style="fill: white" />
            <path d="M638.02,96.244C638.617,96.02 639.381,94.491 639.827,94.028C641.505,92.287 643.446,90.465 645.919,90.058" style="fill: rgb(204, 36, 123)" />
            <path
              d="M641.598,95.907C641.524,96.02 640.847,97.048 640.455,97.524C639.921,98.172 639.328,98.584 638.841,98.767C637.544,99.253 636.122,98.518 635.669,97.125C635.274,95.909 635.747,94.591 636.738,93.953C636.952,93.64 637.791,92.424 638.096,92.107C640.13,89.997 642.547,87.909 645.541,87.417C646.9,87.193 648.171,88.195 648.38,89.653C648.588,91.111 647.654,92.476 646.296,92.7C644.365,93.017 642.912,94.544 641.598,95.907Z"
              style="fill: white" />
            <path d="M490.518,264.076C495.904,264.407 501.688,267.655 505.244,271.512" style="fill: rgb(204, 36, 123)" />
            <path
              d="M490.375,266.744C489.004,266.659 487.954,265.395 488.032,263.922C488.111,262.45 489.289,261.323 490.661,261.408C496.638,261.776 503.068,265.352 507.013,269.632C507.98,270.681 507.971,272.374 506.995,273.412C506.018,274.449 504.44,274.44 503.474,273.391C500.308,269.958 495.171,267.039 490.375,266.744Z"
              style="fill: white" />
            <g transform="matrix(0.924094,-0.382164,0.382164,0.924094,-10.5355,22.0889)">
              <path d="M58.632,41.716C61.409,41.509 66.403,39.382 68.478,42.248" style="fill: rgb(204, 36, 123)" />
              <path
                d="M64.425,47.166C63.858,47.23 62.838,47.352 62.24,47.475C60.955,47.738 59.745,47.978 58.759,48.051C55.425,48.3 52.662,45.662 52.592,42.166C52.523,38.669 55.173,35.628 58.506,35.38C60.276,35.248 62.896,34.426 65.182,34.411C68.538,34.391 71.412,35.564 73.352,38.244C75.322,40.965 74.738,44.968 72.048,47.178C69.695,49.111 66.506,49.036 64.425,47.166Z"
                style="fill: white" />
            </g>
            <path d="M715.377,77.839C718.879,77.506 724.262,74.188 727.369,77.623" style="fill: rgb(204, 36, 123)" />
            <path
              d="M723.516,82.527C723.093,82.583 722.197,82.709 721.662,82.853C719.6,83.405 717.567,84.069 715.904,84.227C712.619,84.539 709.715,81.93 709.425,78.404C709.134,74.878 711.565,71.762 714.85,71.45C716.95,71.25 719.726,69.976 722.282,69.731C725.825,69.391 729.074,70.301 731.656,73.156C733.953,75.696 733.897,79.76 731.53,82.226C729.308,84.541 725.834,84.629 723.516,82.527Z"
              style="fill: white" />
            <path d="M878.96,180.765C878.959,178.098 879.485,172.567 882.503,171.542" style="fill: rgb(204, 36, 123)" />
            <path
              d="M885.473,177.109C885.314,177.925 884.935,179.959 884.936,180.763C884.937,184.303 882.261,187.178 878.963,187.179C875.665,187.181 872.986,184.308 872.985,180.768C872.984,178.049 873.539,173.328 875.271,170.202C876.564,167.87 878.405,166.206 880.701,165.427C883.845,164.359 887.205,166.233 888.2,169.608C889.063,172.538 887.862,175.642 885.473,177.109Z"
              style="fill: white" />
            <path d="M985.106,89.383C985.106,85.361 989.382,81.319 993.109,80.783" style="fill: rgb(204, 36, 123)" />
            <path
              d="M991.082,89.383C991.082,92.923 988.404,95.797 985.106,95.797C981.808,95.797 979.131,92.923 979.131,89.383C979.131,86.38 980.248,83.304 982.226,80.711C984.772,77.37 988.732,74.942 992.316,74.426C995.585,73.957 998.594,76.424 999.032,79.932C999.47,83.441 997.171,86.671 993.902,87.14C993.176,87.245 992.445,87.788 991.846,88.404C991.533,88.727 991.082,88.973 991.082,89.383Z"
              style="fill: white" />
            <path d="M1264.28,108.427C1266.36,105.667 1267.73,102.964 1271.82,103.924" style="fill: rgb(204, 36, 123)" />
            <path
              d="M1270.63,110.209C1270.46,110.399 1270.26,110.642 1270.13,110.82C1269.74,111.347 1269.34,111.905 1268.92,112.467C1266.84,115.216 1263.07,115.638 1260.51,113.408C1257.95,111.179 1257.56,107.136 1259.63,104.387C1263.22,99.638 1266.07,96.008 1273.1,97.659C1276.32,98.416 1278.36,101.838 1277.65,105.296C1276.95,108.723 1273.82,110.907 1270.63,110.209Z"
              style="fill: white" />
            <path d="M1403.97,185.602C1404.6,183.823 1408.76,181.76 1410.09,183.734" style="fill: rgb(204, 36, 123)" />
            <path
              d="M1408.42,189.891C1406.81,191.804 1404.22,192.566 1401.84,191.594C1398.76,190.333 1397.21,186.624 1398.39,183.316C1399.27,180.826 1402.74,177.533 1406.35,176.742C1409.91,175.961 1413.09,177.245 1414.93,179.979C1416.86,182.849 1416.26,186.861 1413.59,188.934C1412.03,190.136 1410.12,190.423 1408.42,189.891Z"
              style="fill: white" />
          </g>
        </g>
      </svg>
    </div>
    <section class="info">
      <div class="info-head">
        <div class="info-title">Indulge in the daily perfection of our handcrafted <span>Italian inspired</span> gelato using the best of <span>local produce</span></div>
        <div class="info-title">Enjoy a scoop that's kind to your taste buds and the planet.</div>
      </div>
      <div class="info-image">
        <img src="../../public/images/gelato-lick.png" alt="a happy person enjoying gelato" />
      </div>
    </section>

    <section class="flavours">
      <div class="flavours-head">
        <h1>Our Flavours</h1>
        <h3>A selection of our customer favourites that are often available on any given day</h3>
      </div>
      <div class="flavours-lists">
        <div class="flavours-content">
          <h1>Dairy</h1>
          <!-- <img src="../../public/images/dairy.svg" alt="A dairy cow" /> -->
          <ul>
            <li v-for="flavour in dairyFlavours" :key="flavour.id">
              <p>{{ flavour.name }}</p>
            </li>
          </ul>
        </div>
        <div class="flavours-content">
          <!-- <img src="../../public/images/vegan.svg" alt="a selection of fruit" /> -->
          <h1>Vegan</h1>
          <ul>
            <li v-for="flavour in veganFlavours" :key="flavour.id">
              <p>{{ flavour.name }}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="flavours-head">
        <h3>We also have seasonal specalities that we love to create.</h3>
      </div>
    </section>

    <section class="story">
      <div class="story-left">
        <h3>Milk from natural dairy. A local, regenerative, ethical farm with happy bovines.</h3>
        <div class="image" id="cows">
          <img src="../../public/images/calves.png" alt="a feild of calves" />
        </div>
      </div>
      <div class="story-right">
        <h3>The best seasonal fruit from local Otago farmers.</h3>
        <div class="image" id="fruit">
          <img src="../../public/images/seasonal-fruit-pick.jpg" alt="apple orchard" />
        </div>
      </div>
      <div class="story-left">
        <h3>Enjoy our freshly baked waffle cups and cones without any waste.</h3>
        <div class="image" id="recycle">
          <img src="../../public/images/cones.jpg" alt="waffle cones" />
        </div>
      </div>
      <div class="story-right">
        <h3>Less fat than icecream with a creamier taste. As if by magic.</h3>
        <div class="image" id="source">
          <img src="../../public/images/gelato-dog.jpg" alt="happy dog licking gelato" />
        </div>
      </div>
    </section>

    <section class="carousel-item">
      <Carousel />
    </section>

    <section class="location">
      <h1 class="title">Come Find us</h1>
      <div class="location-content">
        <div class="location-inner-content">
          <div class="card">
            <div id="shop">
              <img src="../../public/images/shop-front.jpg" alt="Gelato Junkie shop at the Octagon Dunedin" />
            </div>
            <article>
              <h1>№ 8 &centerdot; The Octagon <br />Dunedin</h1>
              <h3>Open 7 days</h3>
              <p><span class="left">Mon</span> <span class="right">6 - 9pm</span></p>
              <p><span class="left">Tues, Wed, Thurs</span> <span class="right">2 - 9pm</span></p>
              <p><span class="left">Fri, Sat, Sun</span> <span class="right">12:30 - 9pm</span></p>
            </article>
          </div>
        </div>
        <div class="location-inner-content">
          <div class="card">
            <div id="ape">
              <img src="../../public/images/gelato-ape2.jpg" alt="Gelato Junkie shop at the Octagon Dunedin" />
            </div>
            <article>
              <h1>Piaggio Ape <br />Where is it?</h1>
              <h3>We might be out and about</h3>
              <p>And we could be anywhere</p>
              <p>Check our socials to find out</p>
              <p>
                <a class="socials" href="https://www.facebook.com/gelatojunkie/" target="_blank"><font-awesome-icon class="social-icons" :icon="['fab', 'facebook']" size="lg" /></a>
                <a class="socials" href="https://www.instagram.com/gelatojunkienz/" target="_blank"><font-awesome-icon class="social-icons" :icon="['fab', 'instagram']" size="lg" /></a>
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";
import Carousel from "./Carousel.vue";

export default {
  name: "HomePage",
  components: {
    Layout,
    Carousel,
  },
  data() {
    return {
      dairyFlavours: [
        { id: 1, name: "Belgian Chocolate" },
        { id: 2, name: "Tiramisu" },
        { id: 3, name: "Black Forest" },
        { id: 4, name: "Cookies & Cream" },
        { id: 5, name: "Pistachio" },
        { id: 6, name: "Kinda Bueno" },
        { id: 7, name: "Tahitian Vanilla" },
        { id: 8, name: "Mint Choc Chip" },
        { id: 9, name: "Hokey Pokey" },
        { id: 10, name: "Dulce de Leche" },
        // { id: 9, name: "add more flavours" },
      ],
      veganFlavours: [
        { id: 1, name: "Snickers" },
        { id: 2, name: "Lemon Meringue" },
        { id: 3, name: "Mango" },
        { id: 4, name: "Waimate Strawberry" },
        { id: 5, name: "Passionfruit" },
        { id: 6, name: "Nutella" },
        { id: 7, name: "Raspberry" },
        { id: 8, name: "Coconut Mocha" },
        { id: 9, name: "Lemon Sorbet" },
        { id: 10, name: "Banana Cheesecake" },
        // { id: 9, name: "add more flavours" },
      ],
    };
  },
  mounted() {
    // let options = {
    //     root: document.querySelector("#scrollArea"),
    //     rootMargin: null,
    //     threshold: 0,
    // };
    // const pagesState = ["home", "hero", "story", "location"];
    // let observer = new IntersectionObserver((entries) => {
    //   const intersectionRatios = [0, 0.2, 0.4, 0.6, 0.8, 1];
    //   console.log(intersectionRatios);
    //   console.log(entries[0].intersectionRatio);
    //   entries.forEach((entry) => {
    //     if (entry.intersectionRatio <= intersectionRatios[1]) {
    //       console.log(`intersection ratio is less than 0.2 at ${entry.intersectionRatio}`);
    //     }
    //     if (entry.isIntersecting) {
    //       console.log(entry);
    //       let i = (pagesState.findIndex((page) => page === entry.target.id) + 2) * 10;
    //       console.log(i);
    //       document.querySelector(".container").style.setProperty("--drip-end", i + "vh");
    //       document.querySelector("#drop").classList.toggle("drip-animation");
    //     }
    //   });
    // });
    // pagesState.forEach((page) => observer.observe(document.getElementById(page)));
    // intersectionRatios.forEach(ratio => observer.observe(document.getElementById(ratio)))
    // for (const ratio of intersectionRatios) {
    //     console.log(`intersection ratio: ${ratio}`)
    // }
  },
  created() {
    //window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      //calculate what page we are on or where we are on the page, and call drip animation at the correct time.
      // document.body.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
      //document.querySelector("#drop").classList.toggle("drip-animation");
      return true;
    },
  },
};
</script>

<style lang="scss">
.hero {
  background-image: url(../../public/images/background1.jpg);
  height: 50rem;
  background-size: cover;
  background-position: center;
  transform: scaleX(-1);
  .hero-head {
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: 100%;
    padding: 9rem 7rem;
    h1 {
      margin: 0;
      font-size: xx-large;
      font-weight: 300;
      color: var(--text-colour);
      text-align: left;
      transform: scaleX(-1);
    }
  }
}
.info {
  background-color: var(--secondary-colour);
  // height: 40vh;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;

  // --mask: radial-gradient(40px at 50% calc(100% - 40px), #000 99%, #0000 10%) calc(50% - 80px) 0/160px 100%,
  //   radial-gradient(40px at 50% calc(100% + 0px), #0000 99%, #000 101%) 50% calc(100% - 40px) / 160px 100% repeat-x;
  // -webkit-mask: var(--mask);
  // // mask: var(--mask);

  .info-image {
    display: flex;
    justify-content: baseline;
    width: 15%;
    
    // z-index: -1;
    img {
      width: 25rem;
      border-radius: 50%;
      // border: 2px solid var(--primary-colour);
      background: var(--background-fade);
    }
  }
  .info-head {
    text-align: center;
    font-size: x-large;
    padding: 1rem;
    z-index: 10;

    .info-title {
      font-weight: 100;
      // display: flex;
      // flex-direction: row;
      color: var(--text-colour);
      max-width: 50vw;
      margin: 0 3rem;
      span {
        color: var(--primary-colour);
      }
    }
    :nth-child(1) {
      font-size: larger;
      font-weight: 100;
      span {
        font-size: xx-large;
        font-weight: 200;
        // padding: 0rem 0.5rem;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
        text-wrap: nowrap;
        margin: 0;
      }
    }
    
    :nth-child(2) {
      font-size: large;
      font-weight: 400;
      font-style: italic;
      // width: 60%;
      // display: flex;
      // justify-content: center;
      align-items: center;
      // padding: 1rem;
    }
  }
}
.svg-wrapper {
  display: flex;
  justify-content: right;
  background-color: var(--secondary-colour);
  max-height: 75vh;
  // transform: scaleY(75%);
  // transform-origin: top;
  // width: 100%;
  // border-top: 1rem solid var(--primary-colour);
  svg {
    bottom: 0;
    width: 100%;
    // transform-origin: top;
    background-color: var(--secondary-colour);
    // transform: rotate(180deg);
  }
}

.flavours {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  .flavours-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .flavours-lists {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    text-align: center;

    .flavours-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0rem 12rem;
      img {
        width: 12rem;
        // height: 10rem;
        object-fit: cover;
        object-position: 0% 75%;
      }
      h1 {
        font-size: 3rem;
        font-weight: 100;
        // padding: 1rem;

        margin: 0;
        font-weight: 300;
      }
      ul {
        // overflow-y: scroll;
        padding: 0;
        // height: 15rem;
        list-style: none;
        li {
          text-decoration: square;
        }
        p {
          text-align: center;
          margin: 5px;
          font-size: x-large;
        }
      }
    }
  }
}

.story {
  background-color: var(--background-colour);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;

  .story-left {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
    h3 {
      margin: 0;
      text-align: right;
      color: var(--text-colour);
      font-size: x-large;
      max-width: 30%;
    }
    .image {
      // margin: 1rem;
      padding: 0 1.5rem;

      img {
        height: 25vw;
        width: 35vw;
        object-fit: cover;
        border-radius: 20px;
      }
    }
  }
}

.story-right {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  h3 {
    margin: 0;
    text-align: left;
    color: var(--text-colour);
    font-size: x-large;
    max-width: 30%;
  }
  .image {
    // margin: 1rem;
    padding: 0 1.5rem;
    img {
      height: 25vw;
      width: 35vw;
      object-fit: cover;
      border-radius: 20px;
    }
  }
}

.carousel-item {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #afd8dd;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-colour);
  .title {
    font-size: xx-large;
    text-transform: capitalize;
    color: var(--primary-colour);
  }
  margin: 1rem;
  .location-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .location-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 2rem;
      .card {
        width: 30rem;
        height: 40rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background-color: var(--secondary-colour);
        border-radius: 20px;
        #shop {
          object-position: right top;
        }
        article {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 50%;
          width: 100%;
          text-align: center;

          p {
            font-size: large;
            margin-bottom: 0.2px;
            padding: 0rem 7rem;
            .left {
              float: left;
            }

            .right {
              float: right;
            }
          }
          h1 {
            line-height: normal;
            font-weight: normal;
            font-size: x-large;
          }
          h3 {
            font-weight: normal;
          }
        }
        div {
          img {
            width: 30rem;
            height: 20rem;
            border-radius: 10px;
            object-fit: cover;
            object-position: 0% 75%;
          }
          border-radius: 10px;
        }
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .hero {
    .hero-head {
      padding: 3rem 2rem;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    .info-image {
      display: flex;
      justify-content: center;
      // z-index: -1;
      img {
        width: 15rem;
      }
    }
    .info-head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      z-index: 10;
      width: 80vw;
      .info-title {
        font-size: large;
        width: 80vw;
        // display: flex;
        // flex-direction: column;
        span {
          font-size: x-large;
        }
      }
      
      
      :nth-child(2) {
        font-size: medium;
        padding: 0rem;
        margin: 1rem;
      }
    }
  }

  .svg-wrapper {
    // right: 0;
    // transform: scale(2);
    // transform: translateX(-5rem)
  }

  .flavours {
    .flavours-lists {
      display: flex;
      flex-direction: column;
      align-items: center;
      .flavours-content {
        margin: 1rem;
        ul li p {
          text-align: left;
        }
      }
    }
  }

  .story {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .story-left {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      h3 {
        text-align: center;
        max-width: 100%;
      }
      .image {
        padding: 0;
        margin: 1rem;
        img {
          height: 20rem;
          width: 80vw;
          // object-fit: cover;
          border-radius: 10px;
        }
      }
    }
    .story-right {
      display: flex;
      flex-direction: column;
      margin: 1rem;
      h3 {
        text-align: center;
        max-width: 100%;
      }
      .image {
        padding: 0;
        margin: 1rem;
        img {
          height: 20rem;
          width: 80vw;
          // object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }

  .carousel-item {
    // width: 100%;
    display: flex;
    justify-content: center;
    // background-color: #afd8dd;
  }

  .location {
    // margin: 1rem;
    .location-head {
      h1 {
        margin: 0;
      }
    }
    .location-content {
      display: flex;
      flex-direction: column;
      .location-inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .card {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 1rem;
          article {
            h1 {
              line-height: normal;
            }
            p {
              padding: 0;
            }
          }
          div {
            img {
              width: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1224px) and (min-width: 840px) {
  .info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 2rem;
    padding: 0 6rem;
    // width: 100%;
    .info-image {
      display: flex;
      justify-content: baseline;
      align-items: left;
      // z-index: -1;
      img {
        width: 15rem;
      }
    }
    .info-head {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: space-evenly;
      padding: 0;
      z-index: 10;
      .info-title {
        display: flex;
        flex-direction: column;
      }
      :nth-child(1) {
        font-size: large;
        padding: 0.5rem;
        span {
          font-size: large;
        }
      }
      :nth-child(2) {
        font-size: large;
        padding: 0.5rem;
        span {
          font-size: large;
        }
      }
      :nth-child(3) {
        font-size: large;
        padding: 0.5rem;
      }
    }
  }
  .location {
    // margin: 1rem;
    .location-head {
      h1 {
        margin: 0;
      }
    }
    .location-content {
      display: flex;
      flex-direction: column;
      .location-inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .card {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding: 1rem;
          article {
            h1 {
              line-height: normal;
            }
          }
          img {
            border-radius: 10px;
          }
        }
        .location-inner-image {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
