<template>
    
    <!-- <router-link @click.stop.prevent="navScroll('#hero')" href="#hero">Home</router-link>
    <router-link @click.stop.prevent="navScroll('#home')" href="#home">Events</router-link>
    <a @click.stop.prevent="navScroll('#location')" href="#location">Come find us</a> -->
    <router-link to="/">Home</router-link>
    <router-link to="/EventsItem">Events</router-link> 
    <!--<router-link to="/CakeShop">Cake Shop</router-link>-->
    <!-- <router-view></router-view> -->
</template>
<script>
export default {
    name: "NavTags",
   
    methods: {
        navScroll(el) {
      var e = document.querySelector(el);
      e.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
    },
    }
}
</script>
<style scoped lang="scss">
    a {
      text-decoration: none;
      color: var(--text-colour);
      transition: 500ms ease-in-out;
      padding: 0.5rem;
    }
    a:hover {
      color: var(--primary-colour);
    }
</style>
