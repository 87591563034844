import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/index.scss";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faEdit,
  faPlus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faEnvelope,
  faCaretDown,
  faShoppingCart,
  faShop,
  faShoppingBasket,
  faCircleArrowLeft,
  faCircleArrowRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faFacebook,
  faInstagram,
  faPhone,
  faEdit,
  faPlus,
  faSave,
  faTrash,
  faBars,
  faSort,
  faMinusCircle,
  faPlusCircle,
  faEnvelope,
  faCaretDown,
  faShoppingCart,
  faShop,
  faShoppingBasket,
  faCircleArrowLeft,
  faCircleArrowRight,
  faTrashCan
);

import { createRouter, createWebHistory } from "vue-router";
import Home from "./components/Home.vue";
import CakeShop from "./components/CakeShop.vue";
import EventsItem from "./components/Events.vue";
import DashboardItem from "./views/DashboardItem.vue";
import LoginItem from "./views/LoginItem.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home },
    { path: "/EventsItem", name: "EventsItem", component: EventsItem },
    { path: "/CakeShop", name: "CakeShop", component: CakeShop },
    { path: "/Login", name: "LoginItem", component: LoginItem },
    { path: "/Dashboard", name: "DashboardItem", component: DashboardItem, meta: { requiresAuth: true } },
  ],
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");
  if (to.matched.some((route) => route.meta.requiresAuth) && !isAuthenticated) {
    next({ name: "LoginItem" });
  } else {
    next();
  }
});

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.mount("#app");
