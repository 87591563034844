<template>
  <div class="navbar">
    <a href="/">
      <img src="Branding.png" alt="Logo" />
    </a>
    <nav>
      <NavTags />
    </nav>

    <div class="hamburger" @click="navbarOpen = !navbarOpen">
      <font-awesome-icon class="ham-icon" icon="bars" />
      <nav :class="navbarOpen ? 'ham-nav-menu' : 'ham-nav-menu-hide'">
        <NavTags />
      </nav>
    </div>
  </div>
</template>

<script>
import NavTags from "./NavTags";
export default {
  data() {
    return {
      navbarOpen: false,
      
    };
  },
  components: {
    NavTags,
  },
  methods: {}
    
};
</script>

<styles scoped lang="scss">
.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  z-index: 10;
  width: 100%;
  // background-color: var(--background-colour);
  align-items: center;
  padding: 0.5rem 0rem;

  a {
    .nav-title {
      padding-left: 1rem;
    }
    text-decoration: none;
    color: var(--primary-colour);
    transition: 500ms ease-in-out;
    &:hover {
      color: var(--primary-colour);
    }
  }

  img {
    display: flex;
    height: 30%;
  }

  nav {
    display: flex;
    a {
      font-size: x-large;
      text-decoration: none;
      padding: 1rem;
      color: var(--text-colour);
    }
  }
  .hamburger {
    display: none;
  }
}

@media only screen and (orientation: portrait) {
  .navbar {
    img {
      height: 5rem;
    }
    nav {
      display: none;
    }
    .hamburger {
      display: block;
      .ham-icon {
        padding-right: 1rem;
      }
      .ham-nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 25vh;
        width: 90%;
        font-size: xx-large;
        text-transform: uppercase;
        position: absolute;
        right: 1rem;
        background: var(--secondary-colour);
        border-radius: 10px;
        
        #nav-option {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid var(--action-colour);
          border-radius: 0.5rem;
          color: var(--text-colour);
        }
      }
      .ham-nav-menu-hide {
        display: none;
      }
    }
  }
}
</styles>
