<template>
  <div class="outer-container">
    <NavBar />
    <div id="first-content" ><slot />
    </div>

    <Footer />
  </div>
</template>

<script>
import NavBar from './NavBar.vue'
import Footer from './Footer.vue'
// import Home from './Home.vue'

export default {
  name: 'LayOut',
  components: {
    NavBar,
    Footer,
    // Home,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.outer-container {
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  // width: 100%;
  background: var(--background-fade);
}


</style>
