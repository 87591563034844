<template>
  <Layout>
    <div class="events-container">
      <div class="events-head">
        <h1>Weddings & Events</h1>
        <p>Photo - Eileen Yeoh</p>
      </div>
      <div class="form-container">
        <h3>
          Please fill out the information if you would like gelato junkie to be at your special day or event. <br />We can provide you with the Piaggio Ape or Self Scooping Party Pans, depending on
          your needs.
        </h3>
        <form @submit="sendEmail" class="events-form">
          <label for="name">Name</label>
          <input type="text" v-model="name" name="name" placeholder="Full Name" required />
          <label for="email">Email</label>
          <input type="email" v-model="email" name="email" placeholder="Email" required />
          <label for="phone">Contact</label>
          <input type="tel" v-model="phone" name="phone" placeholder="Mobile Phone" required />
          <label for="date">Likely Date</label>
          <input type="date" v-model="date" required />
          <label for="venue">Likely Venue</label>
          <input type="text" v-model="venue" name="venue" placeholder="Venue" required />
          <label for="numbers">Approximate Guests</label>
          <input type="number" v-model="numbers" name="approx-numbers" placeholder="Guests" required />
          <label for="message">Any Further Information</label>
          <textarea name="message" v-model="message" id="" cols="30" rows="10"></textarea>
          <input type="submit" value="submit" class="submit" />
          <!-- <button @click.stop.prevent="sendEmail">Submit</button> -->
        </form>
      </div>
      <div class="success">
        <h1>Thank you</h1>
        <p>Your enquiry has been successfully submitted, we will be in touch soon.</p>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "./Layout.vue";

export default {
  name: "EventsItem",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      date: "",
      venue: "",
      numbers: "",
      message: "",
    };
  },
  components: {
    Layout,
  },
  methods: {
    async sendEmail(event) {
      event.preventDefault();
      let data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        date: this.date,
        venue: this.venue,
        numbers: this.numbers,
        message: this.message,
      };
      try {
        const response = await fetch(process.env.VUE_APP_WEB_SHOP_API_URL + "/add/enquiries/new", {
          method: "POST",
          body: JSON.stringify(data),
          headers: { "Content-Type": "application/json" },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          Object.keys(data).forEach((key) => (this[key] = ""));
          const el = document.querySelector(".success");
          el.style.setProperty("display", "flex");
          setTimeout(() => {
            el.scrollIntoView({ behavior: "smooth", block: "center" });
          }, 500);
        }

        const json = await response.json();
        console.log(json);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.events-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .success {
    background-color: var(--secondary-colour);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
    h1 {
      margin: 0;
    }
    p {
      text-align: center;
    }
  }
  .events-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40rem;
    padding: 2px;
    background-image: url(../../public/images/gelato-wedding.jpg);
    background-color: var(--primary-colour);
    background-size: cover;
    background-position: 50%;
    // background-blend-mode: lighten;
    h1 {
      text-align: center;
      font-size: 4rem;
      font-weight: 600;
      color: white;
      padding: 2rem;
      position: absolute;
    }
    img {
      width: 100%;
    }
    p {
      color: #faf8f8b1;
      align-self: flex-start;
      margin-top: auto;
    }
  }
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;

    .events-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin: 1rem;
      font-size: large;
      width: 100%;
    }
    textarea {
      width: 50%;
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin: 1rem;
    }
    .submit {
      width: 50%;
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin: 1rem;
      text-transform: capitalize;
      background-color: var(--primary-colour);
      color: var(--alt-text-colour);
      font-size: 1.5rem;
      transition: 500ms ease-in-out;
    }
    label {
      display: flex;
      justify-content: left;
      width: 50%;
      font-size: 1.5rem;
      color: var(--primary-colour);
      padding: 1rem;
      left: 40px;
    }
    input {
      width: 50%;
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin: 1rem;
      font-size: larger;
    }
    textarea {
      width: 50%;
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin: 1rem;
    }
    .submit {
      width: 52%;
      padding: 1rem;
      border-radius: 10px;
      border: none;
      margin: 1rem;
      text-transform: capitalize;
      background-color: var(--primary-colour);
      color: var(--alt-text-colour);
      font-size: 1.5rem;
      transition: 500ms ease-in-out;

      &:hover {
        background-color: var(--secondary-colour);
        color: var(--primary-colour);
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .events-container {
    .events-head {
      background-position: left;
    }
    .events-form {
      label {
        width: 100%;
      }
      input {
        width: 95%;
      }
      textarea {
        width: 95%;
      }
      .submit {
        width: 105%;
      }
    }
  }
}
</style>
